import React from 'react';
import { useDocumentTitle } from "../../hooks/setDocumentTitle"

function Patterns() {

    useDocumentTitle("Pattern Catalogue | Witty Crafts Design");

    const etsyBaseUrl = "https://wittycraftsdesign.etsy.com/uk/listing/";
    const ravelryBaseUrl = "https://www.ravelry.com/patterns/library/";
    const lovecraftsBaseUrl = "https://www.lovecrafts.com/en-gb/p/";

    // TODO move to seperate file
    const patterns = [
        {
            "title": "Lilianna Cardigan",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "lilianna-cardigan",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1338378843/crochet-pattern-lilianna-cardigan",
                    "title": "ETSY"
                },
                {
                    "url": lovecraftsBaseUrl + "lilianna-cardigan-crochet-pattern-by-witty-crafts-design",
                    "title": "Lovecrafts"
                }
            ],
            "media": [
                {
                    "url": "media/lilianna_cardigan.webp"
                }
            ]
        },
        {
            "title": "I Got You Cushion",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "i-got-you-cushion",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1477147932/crochet-pattern-i-got-you-cushion",
                    "title": "ETSY"
                },
                {
                    "url": lovecraftsBaseUrl + "i-got-you-cushion-crochet-pattern-by-witty-crafts-design",
                    "title": "Lovecrafts"
                }
            ],
            "media": [
                {
                    "url": "media/i-got-you-cushion.webp"
                }
            ]
        },
        {
            "title": "I Got You Cushion 2.0",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "i-got-you-cushion-20",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1714036182/crochet-pattern-i-got-you-cushion-20",
                    "title": "ETSY"
                }
            ],
            "media": [
                {
                    "url": "media/i-got-you-cushion-20.jpg"
                }
            ]
        },
        {
            "title": "Make a Wish Shawl",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "make-a-wish-shawl",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1716749427/crochet-pattern-make-a-wish-shawl",
                    "title": "ETSY"
                }
            ],
            "media": [
                {
                    "url": "media/make-a-wish-shawl.jpg"
                }
            ]
        },
        {
            "title": "Cosy Together Shawl",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "cosy-together-shawl",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1689598135/crochet-pattern-cosy-together-shawl",
                    "title": "ETSY"
                }
            ],
            "media": [
                {
                    "url": "media/cosy-together-shawl.jpg"
                }
            ]
        },
        {
            "title": "Spiced Biscuit Square",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "spiced-biscuit-square",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1284038662/crochet-pattern-spiced-biscuit-square",
                    "title": "ETSY"
                },
                {
                    "url": lovecraftsBaseUrl + "spiced-biscuit-square-crochet-pattern-by-witty-crafts-design",
                    "title": "Lovecrafts"
                }
            ],
            "media": [{ "url": "media/spiced_biscuit_square.webp" }]
        },
        {
            "title": "Spiced Biscuits Blanket",
            "slug": "spiced-biscuits-blanket",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "spiced-biscuits-blanket",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1006121360/crochet-pattern-spiced-biscuits-blanket",
                    "title": "ETSY"
                },
                {
                    "url": lovecraftsBaseUrl + "spiced-biscuits-blanket-crochet-pattern-by-witty-crafts-design",
                    "title": "Lovecrafts"
                }
            ],
            "media": [
                {
                    "url": "media/spiced_biscuits_blanket.jpg"
                }
            ]
        },
        {
            "title": "Dana Square",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "dana-square",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1777802783/crochet-pattern-dana-square-crochet",
                    "title": "ETSY"
                }
            ],
            "media": [
                {
                    "url": "media/dana-square.webp"
                }
            ]
        },
        {
            "title": "Maple Pouch",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "maple-pouch",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1749906086/crochet-pattern-maple-pouch-crochet",
                    "title": "ETSY"
                }
            ],
            "media": [
                {
                    "url": "media/maple-pouch.jpg"
                }
            ]
        },
        {
            "title": "Fox's Way Shawl",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "foxs-way-shawl",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "961713140/crochet-shawl-pattern-instant-download?ref=shop_home_feat_1",
                    "title": "ETSY"
                },
                {
                    "url": lovecraftsBaseUrl + "foxs-way-shawl-crochet-pattern-by-witty-crafts-design",
                    "title": "Lovecrafts"
                }
            ],
            "media": [
                {
                    "url": "media/foxes_way_shawl.jpg"
                }
            ]
        },
        {
            "title": "Festive Jewels Ornaments",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "festive-jewels-ornaments",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1317772040/crochet-pattern-festive-jewels-ornaments",
                    "title": "ETSY"
                }
            ],
            "media": [{ "url": "media/festive_jewels_ornaments.jpg" }]
        },
        {
            "title": "Hexibob Blanket",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "hexibob-blanket",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1564563768/crochet-pattern-hexibob-blanket",
                    "title": "ETSY"
                },
                {
                    "url": lovecraftsBaseUrl + "hexibob-blanket-crochet-pattern-by-witty-crafts-design",
                    "title": "Lovecrafts"
                }
            ],
            "media": [
                {
                    "url": "media/hexibob-blanket.jpg"
                }
            ]
        },
        {
            "title": "Bonnie Bobble Jumper",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "bonnie-bobble-jumper",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1525367953/crochet-pattern-bonnie-bobble-jumper",
                    "title": "ETSY"
                }
            ],
            "media": [
                {
                    "url": "media/bonnie-bobble-jumper.jpg"
                }
            ]
        },
        {
            "title": "Dolci Beanie",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "dolci-beanie",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1391595172/crochet-pattern-dolci-beanie",
                    "title": "ETSY"
                },
                {
                    "url": lovecraftsBaseUrl + "dolci-beanie-crochet-pattern-by-witty-crafts-design",
                    "title": "Lovecrafts"
                }
            ],
            "media": [
                {
                    "url": "media/dolci-beanie.jpg"
                }
            ]
        },
        {
            "title": "Dolci Headband",
            "slug": "",
            "summary": "",
            "links": [
                {
                    "url": ravelryBaseUrl + "dolci-headband",
                    "title": "Ravelry"
                },
                {
                    "url": etsyBaseUrl + "1428092045/crochet-pattern-dolci-headband",
                    "title": "ETSY"
                }
            ],
            "media": [
                {
                    "url": "media/dolci-headband.jpg"
                }
            ]
        }
    ]

    return (
        <>
            <h1>Pattern Catalogue</h1>
            <section>
                <div className="row">
                    {patterns.map(item =>
                        <div className="card border-0 col-sm-12 col-md-4">
                            <img src={item.media[0].url} className="img-fluid rounded shadow" alt={item.title} />
                            <div className="card-body mt-2">
                                <h2 className="card-title">
                                    {item.slug.length > 0 
                                        ? <a href={`/patterns/${item.slug}`}>{item.title}</a>
                                        : item.title
                                    }
                                </h2>
                                <p className="card-text">{item.summary}</p>
                                {item.links.map(link =>
                                    <a href={link.url} className="btn btn-primary m-1" target="_blank">{link.title}</a>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}
export default Patterns